import React from 'react';
import clsx from 'clsx';
import styles from './about.module.scss';
import { Link } from 'gatsby';

export const About: React.FC = () => {
  return (
    <div className={styles['about-wrapper']}>
      <div className="container">
        <header className={styles['page-header']}>
          <h1>Over de Haagse Makers Week</h1>
          <p className={styles['first']}>
            Na de eerste Hoe Maakt U Het? Makersbeurs in 2014 is het tijd voor een nieuwe bijeenkomst over
            vindingrijkheid in Den Haag. Een jaarlijks feest over creativiteit, passie, ambacht, inspiratie & creatie.
            We brengen gevestigde en nieuwe makers, ontwerpers, merken, studios en werkplaatsen in Den Haag.
          </p>
        </header>

        <section className={styles['section']}>
          <p>
            Bij de Haagse Makers Week staat authenticitiet centraal, het programma richt zich op een breed publiek,
            verbonden door hun waardering voor vindingrijkheid, ambact, creatie en samenwerking. Door het samengesteld
            programma van activiteiten door heel Den Haag kunnen bezoekers hun eigen route bepalen, de makers ontmoeten
            en toegang krijgen tot speciale tentoonstellingen, demonstraties en workshops.
          </p>
          <blockquote className={clsx(styles.quote)}>
            Den Haag heeft een lange geschiedenis van gallieries, festivals en peace summits. Nu is er de Haagse
            Makersweek. Een magische combinatie van vindingrijkheid, creatie, inspiratie, passie, ambacht,
            doorzettingsvermogen en samenwerking die tot uiting komen in goed gemaakte dingen.
            <div className={styles['quote-src']}>Arn van der Pluijm, Initiator The Hague Make Week</div>
          </blockquote>
          <p>
            De Haagse Makers Week is onderdeel van een wereldwijde trend, waarbij mensen op zoek gaan naar lokaal,
            duurzaam en zelfgemaakt. Met waardering voor passie en ambacht. Van grote merken tot individuele makers en
            kunstenaars: ieder vertelt hun eigen vrhaal. Elk jaar, in september, staan de makers, organisaties en
            locaties in de spotlight, tegen de achtergrond van Den Haag. dit moment gebruiken we om het makerschap in al
            haar facetten te vieren en met elkaar te delen.
          </p>
        </section>

        <section className={styles['section']} id="faq">
          <h2>Frequently Asked Questions</h2>
          <h4>Waar is de Haagse Haag Makers week?</h4>
          <p>
            De Haagse Makers Week 2020 is een open programma door de hele stad. Makers, werkplaatsen, winkels en andere
            maakplekken maken zelf de inhoud van de makers week. Iedereen die zich maker voelt en dat met anderen wil
            delen wordt uitgenodigd om deel te nemen. Het <Link to="/programma">programma</Link> is hier te vinden, en
            op de <Link to="/locaties">locatie-pagina</Link> staat een overzicht van de locaties die meedoen.
          </p>
          <h4>Hoe koop ik tickets om de activiteiten te bezoeken?</h4>
          <p>
            Een groot gedeelte van de activiteiten is te gratis te bezoeken, reserveren is daar niet nodig. Voor sommige
            activiteiten is (betaald) reserveren nodig, op de event pagina van deze activiteit is een link te vinden
            naar de booking pagina.
          </p>
          <h4>Hoe kom ik aan een programma gids?</h4>
          <p>In 2020 hebben we geen geprinte programma gids. Het programma is op de website te vinden.</p>
          <h4>Hoe kan ik bijdragen aan het programma (komende jaren)? </h4>
          <p>
            Neem contact op met <a href="mailto: hoemaaktuhet@haagsemakers.nl">Haagse Makers</a> voor meer details.
          </p>
        </section>

        <section className={styles['section']} id="pers">
          <h3>Pers &amp; Marketing</h3>
          <p>
            Neem voor pers- en marketing-vragen contact op met{' '}
            <a href="mailto: redactie@haagsemakers.nl">redactie@haagsemakers.nl</a>
          </p>
        </section>
        <section className={styles['section']} id="contact">
          <h3>Contact</h3>
          <ul>
            <li>
              Algemeen: <a href="week@haagsemakers.nl">redactie@haagsemakers.nl</a>
            </li>
            <li>
              Programma (nieuwe deelnemers): <a href="redactie@haagsemakers.nl">redactie@haagsemakers.nl</a>
            </li>
            <li>
              Sponsoring en andere vragen: : <a href="arn@haagsemakers.nl">arn@haagsemakers.nl</a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};
